import { ReactElement } from 'react';
import { ThunkDispatchType, Grant } from '../../store';
import {  useDispatch } from 'react-redux';
import { MS_SIGN_IN_BUTTON } from '../../resourceUrls';
import classes from './SignIn.module.css';
import { MICROSOFT_OAUTH_REDIRECT_URI } from '../../constants';
import { getMsLoginUrl } from '../../store/auth/actions';
// eslint-disable-next-line max-len
// More info: https://docs.microsoft.com/en-us/graph/tutorials/react?WT.mc_id=Portal-Microsoft_AAD_RegisteredApps&tutorial-step=3

interface ComponentProps {
  grant?: Grant;
}

type Props = ComponentProps;

const MicrosoftSignIn = ({
  grant
}: Props): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();
  
  const handleGrantOfflineClick = (): void => {
    dispatch(getMsLoginUrl(undefined, `${window.location.origin}${MICROSOFT_OAUTH_REDIRECT_URI}`)).then(authUrl => {
      window.location.href = authUrl;
    });
  };

  return (
    <div className={classes.signInContainer}>
      {!grant && (
        <div className={classes.inputContainer}>
          <p className={classes.connectText}>Connect <b>Microsoft</b></p>
          <input 
            className={classes.signInButton}
            alt='Sign in with Microsoft' 
            type='image'
            id='signIn' 
            src={MS_SIGN_IN_BUTTON}
            onClick={handleGrantOfflineClick}/>
        </div>
      )}
    </div>
  );
};

export default MicrosoftSignIn;
