import { ReactElement, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { trackEvent } from '../../utils/appAnalyticsUtils';
import { 
  actions, ThunkDispatchType,
  OAuthProvider,
} from '../../store';
import { PROVIDER, EVENT_TYPE } from '../../constants';
import { useLocation,  useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash-es';


type RouteParams = { provider?: string };
type RouteState = { initSchedule: boolean };

interface ComponentProps {
  saving: boolean;
  handleSignInDone: (providerId: number) => void;
  children: ReactElement | null;
  redirectTo?: { path: string; state: RouteState };
  handleSignInStart: () => void
}

type Props = ComponentProps;

const OAuthRedirectHandler = ({
  saving, handleSignInDone, redirectTo, children, handleSignInStart
}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<RouteParams>();
  const dispatch = useDispatch<ThunkDispatchType>();
  const [, setSearchParams] = useSearchParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const saveOAuthGrant = useCallback(debounce((code: string, provider: OAuthProvider) =>
    dispatch(actions.auth.saveOAuthGrant(code, provider)), 500), [dispatch]);


  const handleIncomingSignInRequest = useCallback(async () => {
    handleSignInStart();
    const parsed = queryString.parse(location.search);
    // Google, MS, Zoom all use 'code'
    // Salesforce users 'oauth_code'
    const codeParam = parsed['code'] || parsed['oauth_code'];
    const code = Array.isArray(codeParam) ? codeParam[0] : codeParam;
    let provider: { id: number; name: string } | undefined;
    let eventType: EVENT_TYPE|undefined;
    if (params.provider) {
      if (params.provider === 'microsoft') {
        provider = PROVIDER.MICROSOFT;
        eventType = EVENT_TYPE.SCHEDULING_OAUTH_MICROSOFT;
      } else if (params.provider === 'zoom') {
        provider = PROVIDER.ZOOM;
        eventType = EVENT_TYPE.SCHEDULING_OAUTH_ZOOM;
      } else if (params.provider === 'salesforce') {
        provider = PROVIDER.SALESFORCE;
        eventType = EVENT_TYPE.SALES_OAUTH_SALESFORCE;
      }
    }

    // To do incremental permissions, we need to hit this even if the user has a valid grant. 
    if (provider && code && !saving) {
      const OAuthRes = await saveOAuthGrant(code, provider);
      if (eventType) trackEvent(eventType);
      if (!OAuthRes) {
        handleSignInDone(provider.id);
        if (redirectTo) {
          navigate(redirectTo.path, {state: redirectTo.state, replace: true});
        } else {
          setSearchParams('');
        }
      }
    }
  }, [
    handleSignInDone, handleSignInStart, location.search, navigate, params.provider,
    redirectTo, saveOAuthGrant, saving, setSearchParams
  ]);

  useEffect(() => {
    handleIncomingSignInRequest();
  }, [
    handleIncomingSignInRequest
  ]);

  return redirectTo ? null : children;
};

export default OAuthRedirectHandler;
