import { ReactElement } from 'react';

import { Grant, ThunkDispatchType } from '../../store';
import {  useDispatch } from 'react-redux';
import { GOOGLE_SIGN_IN_BUTTON, INCREMENTAL_CALENDAR_SCOPES_IMAGE } from '../../resourceUrls';
import classes from './SignIn.module.css';
import { getGoogleBookingScope } from '../../utils/authUtils';
import { Box, styled, Typography } from '@mui/material';
import OAuthButton from './OAuthButtons';
import { PROVIDER } from '../../constants';
import { signInWithGoogle } from '../../store/auth/actions';


const StyleImage = styled("img", {label: "StyledImage"})(({
  marginTop: 12,
  width: "50%",
}));

interface ComponentProps {
  grant?: Grant;
  onOAuthGranted: (providerId: number) => void;
}

type Props = ComponentProps;

const PopUpContent = () => <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
  <Typography>
    Cabinet requires additional permissions to your calendar. To provide those,
    click "Try Again" and be sure to check the boxes shown in the image below.
  </Typography>
  <StyleImage src={INCREMENTAL_CALENDAR_SCOPES_IMAGE}/>
  <Typography>
    If you have questions about how we use your data, please contact support@joincabinet.com.
  </Typography>
</Box>;


const GoogleSignIn = ({
  onOAuthGranted, grant
}: Props): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();

  return (
    <OAuthButton 
      onOAuthGranted={onOAuthGranted}
      signIn={() => dispatch(signInWithGoogle(getGoogleBookingScope()))}
      PopUpContent={PopUpContent}
      provider={PROVIDER.GOOGLE}
    >
      {({onClick}) => !grant ? 
        <div className={classes.inputContainer}>
          <p className={classes.connectText}>Connect <b>Google</b></p>
          <input 
            className={classes.signInButton}
            alt='Sign in with Google' 
            type='image'
            id='signIn' 
            src={GOOGLE_SIGN_IN_BUTTON} 
            onClick={onClick}/>
        </div>
        :
        <></>
      }
    </OAuthButton>
  );
};

export default GoogleSignIn;
