import { ReactElement } from 'react';
import { ThunkDispatchType, Grant } from '../../store';
import {  useDispatch } from 'react-redux';
import { SALESFORCE_SIGN_IN_BUTTON } from '../../resourceUrls';
import classes from './SignIn.module.css';
import { getSalesforceLoginUrl } from '../../store/auth/actions';
// eslint-disable-next-line max-len
// More info: https://docs.microsoft.com/en-us/graph/tutorials/react?WT.mc_id=Portal-Microsoft_AAD_RegisteredApps&tutorial-step=3

interface ComponentProps {
  grant?: Grant;
}

type Props = ComponentProps;

const salesforceOAuthRedirectPath = "/integrations/oauth/salesforce";

const SalesforceSignIn = ({
  grant
}: Props): ReactElement => {

  const dispatch = useDispatch<ThunkDispatchType>();
  
  const handleGrantOfflineClick = (): void => {
    dispatch(getSalesforceLoginUrl(salesforceOAuthRedirectPath)).then(authUrl => {
      window.location.href = authUrl;
    });
  };

  return (
    <div className={classes.signInContainer}>
      {!grant && (
        <div className={classes.inputContainer}>
          <p className={classes.connectText}>Connect <b>Salesforce</b></p>
          <input 
            className={classes.signInButton}
            alt='Sign in with Salesforce' 
            type='image'
            id='signIn' 
            src={SALESFORCE_SIGN_IN_BUTTON}
            onClick={handleGrantOfflineClick}/>
        </div>
      )}
    </div>
  );
};

export default SalesforceSignIn;
