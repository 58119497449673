import { ButtonProps, styled } from '@mui/material';
import React, { ReactElement } from 'react';
import { CabButton, CabIcon } from '@CabComponents';
import { IconType } from 'react-icons';

interface Props {
  Icon: IconType;
  text: string;
  onClick: (event: React.MouseEvent<HTMLSpanElement> | undefined) => void;
  sx?: ButtonProps['sx'];
}

export const IconLinkButton = ({onClick, Icon, text, sx}: Props): ReactElement => (
  <CabButtonStyled
    buttonType='text'
    onClick={onClick}
    icon={<CabIcon alt={text} Icon={Icon} />}
    sx={sx}
  >
    {text}
  </CabButtonStyled>
);

const CabButtonStyled = styled(CabButton, {
  label: 'CabButtonStyled',
})(( { theme }) => {
  return {
    fontSize: 14,
    height: 22,
    fontWeight: 300,
    fontStyle: 'italic',
    '& .MuiButton-startIcon': {
      marginRight: 4,
      marginLeft: 0,
    },
    '& .button-text': {
      paddingTop: 2
    },
    '& svg': {
      fontSize: '16px !important',
    }
  };
});

export default IconLinkButton;